import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Box } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
const ethers = require("ethers")

const TicketPurchase = ( props ) => {
    const [tickets, setTickets] = useState(1);
    const [error, setError] = useState('');
    const pricePerTicket = 0.001; // ETH
    // const serviceFee = 0.0001; // ETH
    const { remainingTickets, lotteryContract, winnings, endOfSell } = props;
    
    const handleTicketChange = (event) => {
        const value = event.target.value;
        if (value === '' || (value >= 1 && value <= remainingTickets)) {
            setTickets(value);
            setError(''); // Clear error if within range
        } else {
            setError(`To avoid abuse and gambling. Please enter a number between 1 and ${remainingTickets}.`);
        }
    };

    const handleTicketOnBlur = (event) => {
        const value = event.target.value;
        if (value === '' || value === 0) {
            // Update the state with the new value if it's empty or 0
            setTickets(1);
        }
    };

    const getTotalCost = () => {
        return (tickets * pricePerTicket).toFixed(4); // Adjust toFixed for precision
    };

    const buyTickets = async () => {
        const notifications = toast.loading('Buying tickets. Please wait until Metamask process complete!')
        try {
            const transaction = await lotteryContract.BuyTickets({ value: ethers.utils.parseEther(getTotalCost()) });
            await transaction.wait();

            toast.success('Ticket bought successfully!', {
                id: notifications
            })
            console.log('Ticket bought successfully');
            window.location.reload();
        } catch (error) {
            toast.success('Metamask process failed or rejected by user', {
                id: notifications
            })
            window.location.reload();
            console.log('error');
        }
    };

    return (
        <>
            <Box sx={{ maxWidth: '100%', m: 'auto', p: 3, backgroundColor: '#0d1d1c', borderRadius: 1 }}>
                <TextField
                    label="Tickets ( MAX 200 tickets )"
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: 200 } }}
                    value={tickets}
                    onChange={handleTicketChange}
                    onBlur={handleTicketOnBlur}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    sx={{ mb: 2, input: { color: '#fff' } }}
                    error={!!error}
                    helperText={error}
                    disabled={ endOfSell || winnings > 0 }
                />
                <Grid container spacing={2} sx={{ mt: 2 }} color="#fff">
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">Price per Ticket:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" align="right">
                        0.001 ETH
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">Total Cost of Tickets:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" align="right" sx={{ fontWeight: 'bold' }}>
                        {getTotalCost()} ETH
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography variant="body1" align="right" sx={{ fontStyle: 'italic', color: '#a0a0a0' }}>
                        + Transaction & Gas Fee: fee will be charged on Metamask.
                        </Typography>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 3, py: 1.5, '&.Mui-disabled': {
                        color: 'white', // Light color for text
                        backgroundColor: 'lightgrey' // Light color for background
                    } }}
                    onClick={buyTickets}
                    disabled={endOfSell || winnings > 0}
                    >
                    Buy {tickets} Ticket(s) for {getTotalCost()} ETH
                </Button>
                { winnings > 0 && (
                     <Typography gutterBottom variant="h6" component="div" align="center" sx={{ fontWeight: 'bold', color: '#0ab9b9' }}>
                        You MUST withdraw current winnings to continue playing the next draw 
                    </Typography>
                )}
            </Box>
            <ToastContainer autoClose={5000} />
        </>
    );
};

export default TicketPurchase;
