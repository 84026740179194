/* eslint-disable react-hooks/exhaustive-deps */
import CountdownTimer from './CountdownTimer';
import { Box } from '@mui/material';
import AdminControl from './AdminControl';
import { useEffect, useState } from 'react';
import WinnerChicken from './WinnerChicken';
import LastWinner from './LastWinner';
const ethers = require("ethers")

const MainPage = ( props ) => {
    const { currentAccount, tickets, remainingTickets, lotteryContract, currentWinningReward, operatorTotalCommission, lotteryOperator } = props.data;
    const [winnings, setWinnings] = useState(ethers.BigNumber.from(0));
    const [lastWin, setLastWin] = useState('');
    const { REACT_APP_OWNER_ADDRESS } = process.env;
    
    

    useEffect(() => {
        console.log("dsasdas:", lotteryContract)
        // Call the function from the contract
        const getLastWinner = async (  ) => {
            try {
                const lastWinner = await lotteryContract.lastWinner();
                setLastWin(lastWinner);
            } catch (err) {
                console.log('getLastWinner error')
            }
        }
        if (lotteryContract) {
            getLastWinner();
        }
    }, []);

    useEffect(() => {
        const  getWinning = async () => {
            try {
            if (lotteryContract && currentAccount) {
                const winningsAmount = await lotteryContract.getWinningsForAddress(currentAccount);
                setWinnings(winningsAmount);
            }
            } catch (err) {
                console.log('getWinning error')
            }
        }
        if (lotteryContract) {
            getWinning();
        }
    }, [tickets, currentAccount]);

    return (
        <>
            {/* LastWinner Section -For both admin and users */}
            <LastWinner lastWin={lastWin} />
            
            {/* Admin Control Section - Only for admin */}
            { REACT_APP_OWNER_ADDRESS === currentAccount && (
                <AdminControl lotteryContract={lotteryContract} totalCommission={operatorTotalCommission} />
            )}

            {/* Winner Section - Only for client */}
            { (currentAccount && REACT_APP_OWNER_ADDRESS !== currentAccount && winnings > 0) && (
                <WinnerChicken winnings={winnings} lotteryContract={lotteryContract} />
            )}

            {/* CountdownTimer Section */}
            <CountdownTimer 
                currentAccount={currentAccount} 
                currentWinningReward={currentWinningReward} 
                remainingTickets={remainingTickets}
                lotteryOperator={lotteryOperator}
                lotteryContract={lotteryContract}
                totalTickets={tickets}
                winnings={winnings}
            />
            
            {/* Information Section */}
            <Box display="flex" justifyContent="center" gap={2}
                sx={{
                    backgroundImage: 'linear-gradient(to right, #0a1817 0%, #2575fc 100%)',
                    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)', // Adds a subtle shadow for depth
                    padding: 2,
                }}>
                {/* Winner Table */}
                {/* <InformationCenter /> */}
            </Box>
        </>
    )
};

export default MainPage;