import { Button, Card, CardContent, Box, Grid, Typography } from "@mui/material";
import { formatEther } from "../utils/Common";
import { toast, ToastContainer } from 'react-toastify';
import '../Countdown.css';

const WinnerChicken = ({ winnings, lotteryContract }) => {

    const onWithdrawWinnings = async () => {
        const notifications = toast.loading('Withdrawing Winnings ...')
        try {
            const transaction = await lotteryContract.WithdrawWinnings();
            await transaction.wait();

            toast.success('Withdraw Winnings successfully', {
                id: notifications
            })
            console.log('Withdraw Winnings successfully');
            window.location.reload();
        } catch (error) {
            toast.success('Metamask process failed or rejected by user', {
                id: notifications
            })
            console.log('error');
        }
    };


    return (
        <>
            <Box sx={{
                backgroundColor: '#fdff59', // Retain transparency for a layered look
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0', // Add some vertical padding
            }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={8} lg={6}> {/* Adjust grid sizing for better responsiveness */}
                        <Card sx={{ backgroundColor: '#123D40', color: '#E9FF70', boxShadow: 5 }}> {/* Enhance contrast and vibrancy */}
                            {
                                (lotteryContract && winnings > 0) && (
                                    <CardContent>
                                
                                            <Typography gutterBottom variant="h4" component="div" align="center" sx={{ fontWeight: 'bold', color: '#FFD700' }}>
                                                🎉 Congratulations, Winner! 🎉
                                            </Typography>
                                            <Typography variant="h6" align="center" sx={{ color: '#E9FF70' }}>
                                                You've won {formatEther(winnings.toString())} !
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                                <Button 
                                                    variant="contained" 
                                                    sx={{ 
                                                        backgroundColor: '#FFD700', 
                                                        '&:hover': { 
                                                            backgroundColor: '#C5A000' 
                                                        }, 
                                                        fontWeight: 'bold',
                                                    }}
                                                    onClick={onWithdrawWinnings}
                                                >
                                                    <Typography
                                                        variant="h8"
                                                        sx={{
                                                            color: '#f7ffff',
                                                            padding: '10px',
                                                            animation: 'colorfulFlash 2s infinite'
                                                        }}
                                                    >
                                                        Withdraw Winnings
                                                    </Typography>
                                                </Button>
                                            </Box>
                                </CardContent>
                                )
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <ToastContainer autoClose={5000} />
        </>
    )
};

export default WinnerChicken;