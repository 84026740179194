import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CasinoIcon from '@mui/icons-material/Casino';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { shortenAddress } from '../utils/Common';
import HowToPlayPopup from './HowToPlay';

const Header = ( { data } ) => {
    const { currentAccount, logout } = data;

    return (
        <AppBar position="static" sx={{ bgcolor: "#0b1918", color: "white" }}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                <CasinoIcon />
            </IconButton>
            <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
                Random Ethereum
            </Typography>
           
            <Stack direction="row" spacing={2} alignItems="center">
                {currentAccount ? (
                <>
                    <Typography variant="body1" color="inherit">
                        Connected as: {shortenAddress(currentAccount)}
                    </Typography>
                    <Button color="inherit" onClick={logout}>Logout</Button>
                </>
                ) : null}
                <HowToPlayPopup />
            </Stack>
            </Toolbar>
        </Container>
        </AppBar>
    )
}

export default Header;