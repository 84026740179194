import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardContent, Box } from '@mui/material';
import '../Countdown.css';
import { formatTotalPools } from '../utils/Common';
import TicketPurchase from './TicketPurchase';
import Countdown from 'react-countdown';

const CountdownTimer = ( props ) => {
    const { totalTickets, currentWinningReward, remainingTickets, currentAccount, lotteryContract, winnings } = props;
    const [userTicket, setUserTicket] = useState(0);
    const [expiration, setExpiration] = useState(null);
    const [endOfSell, setEndOfSell] = useState(false);
    
    const currentReward = formatTotalPools(currentWinningReward);

    const getExpiration = async () => {
        try {
            const expTimer = await lotteryContract.expiration();
            setExpiration(expTimer.toNumber());
        } catch (error) {
            console.log('error');
        }
    };
    useEffect(() => {
        if (lotteryContract) {
            getExpiration();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiration, lotteryContract]);

    useEffect(() => {
        if (!totalTickets) return;

        const noOfUserTickets = totalTickets.reduce((total, ticketAddress) => (
            ticketAddress === currentAccount ? total + 1 : total
        ), 0)
        setUserTicket(noOfUserTickets);
    }, [totalTickets, currentAccount]);

    const countDownComplete = () => {
        setEndOfSell(true);
        console.log('countDownComplete', endOfSell);
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return(
            <>
                <CardContent>
                    <Typography variant="h6" color="#f7ffff" padding= "10px">
                            Time Remaining
                    </Typography>
                    <Typography variant="h5" color="#f7ffff" padding= "10px">
                        <div className="time-box" color="green"><p>{hours}</p></div>
                        <i className='label'>Hours</i>
                        <div className="time-box" color="orange"><p>{minutes}</p></div>
                        <i className='label'>Minutes</i>
                        <div className="time-box" color="orange"><p>{seconds}</p></div>
                        <i className='label' >Seconds</i>
                    </Typography>
                    { completed && (
                        <Typography variant="h8" color="#0ab9b9" padding= "10px">
                            Ticket Sales have now CLOSED. Please stay tuned for the next draw!
                        </Typography>
                    )}
                </CardContent>
            </>
        )
    };

    return (
        <>

            <Box sx={{
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                p: 0.1,
            }}>
                <Grid container spacing={0.1}>
                    <Grid item xs={4}>
                        <Card sx={{ backgroundColor: '#0b1918', color: '#fff' }}>
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>
                                    Total Pool  🎉 
                                </Typography>
                                <Typography variant="h6">
                                    {currentReward} ETH
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ backgroundColor: '#0b1918', color: '#fff' }}>
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>
                                    Tickets Remaining
                                </Typography>
                                <Typography variant="h6">
                                    {remainingTickets}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ backgroundColor: '#0b1918', color: '#fff' }}>
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>
                                    Your Tickets
                                </Typography>
                                <Typography variant="h6">
                                    { userTicket || 0 }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="40vh"
                sx={{
                    backgroundColor: '#0b1918',
                    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)', // Adds a subtle shadow for depth
                }}
            >
                {expiration && (
                    <Card sx={{ minWidth: 275, backgroundColor: '#153a34', borderRadius: '16px' }}>
                        <Countdown key={expiration} date={new Date(expiration * 1000)} renderer={renderer} onComplete={countDownComplete}/>
                    </Card>
                )}
                
            </Box>
            {/* Tables Section */}
            <Box display="flex" justifyContent="center" gap={2}
                //  flexWrap="wrap"
                sx={{
                    backgroundColor: '#0a1817',
                    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)', // Adds a subtle shadow for depth
                    padding: 2,
                }}>

                {/* Ticket Purchase */}
                <Card sx={{ width: '100%', maxWidth: '800px' }}>
                    <TicketPurchase remainingTickets={remainingTickets} lotteryContract={lotteryContract} winnings={winnings} endOfSell={endOfSell}/>
                </Card>
            </Box>
            <Card sx={{ minWidth: 275, backgroundColor: '#153a34', textAlign:'center' }}>
                <CardContent>
                    <Typography variant="h6" color="#f7ffff" padding='10px'>
                        <i>If you are the winner, a message like below will be displayed.</i>
                    </Typography>
                    <img src="/winner.png" alt="Winner" style={{ maxWidth: '150%', height: '150px' }} />
                </CardContent>
            </Card>
        </>
    );
};

export default CountdownTimer;
