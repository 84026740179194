import { Box, Button, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login'; // Import an icon for the login button
import InfoIcon from '@mui/icons-material/Info';

const LoginPage = ( {connectWallet, setLoggedIn} ) => {

    return(
        <>
            <Box sx={{
                backgroundColor: '#0b1918',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // Ensure content is vertically centered
                minHeight: '100vh',
                padding: 2, // Add some padding around the box
                }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Card sx={{
                        backgroundColor: '#123d30', // Slightly different shade for depth
                        color: '#fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add some shadow for elevation
                    }}>
                        <CardContent>
                        <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }} gutterBottom>
                            Welcome to Random Ethereum
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Get started by login using your Metamask.
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: 'background.paper',
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1,
                            mt: 2, // margin top for spacing from previous content
                            color: 'text.secondary'
                            }}>
                            <InfoIcon sx={{ mr: 1, color: 'info.main' }} />
                            <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                Don't have MetaMask? 
                                <Link href="https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" rel="noopener" sx={{ ml: 0.5, textDecoration: 'none', fontWeight: 'bold', color: 'info.dark', '&:hover': { textDecoration: 'underline' }}}>
                                Install it here
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={connectWallet}
                            startIcon={<LoginIcon />} // Add an icon to the button
                            >
                            Login with Metamask
                            </Button>
                        </Box>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default LoginPage;