import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { Divider } from '@mui/material';
const HowToPlayPopup = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        <Button variant="contained" color="primary" onClick={handleClickOpen} disableElevation>
            How To Play
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">How To Play & Rules</DialogTitle>
            <DialogContent>
            <Typography variant="h4" gutterBottom color='#f31585'>
              Only accepted network Optimism <img src="/op-logo.png" alt="Optimism" style={{ maxWidth: '35%', height: '35px' }} />
            </Typography>
            <Typography gutterBottom>
                Ticket Price: 0.001 ETH
            </Typography>
            <Typography gutterBottom>
                Commission: 10% (Based on total winnings)
            </Typography>
            <Typography variant="body2" color="text.secondary" align="left">
                🎉 Total Winnings (Total Pool) = Total * (Ticket Price - Ticket Commission)
            </Typography>
            
            <Divider style={{ margin: '20px 0' }} />
            
            <Typography>
                <strong>Example: </strong>
                If you buy 100 tickets<br />
                🎉 Total Winnings = (100 * (0.001 - 0.0001)) = 0.009 ETH
            </Typography>
            
            <Divider style={{ margin: '20px 0' }} />
            
            <Typography style={{ margin: '20px 0' }} >
                There are 200 tickets for each drawing. Lucky lottery every 30 minutes. The winner will receive money into their account and You can withdraw money immediately to your Metamask wallet.
            </Typography>

            <Typography style={{ fontWeight: 'bold', margin: '20px 0' }} >
                Withdraw money if you were the winner in the previous draw.
            </Typography>
            <Divider />
            <Typography variant='h6' style={{ fontWeight: 'bold', margin: '20px 0' }} >
                If you don't withdraw your money then you NOT ALLOW join next draw.
            </Typography>

            <Typography>
                To participate in the game, follow these steps:
                <ol>
                <li>Input the number of ticket you want to buy.</li>
                <li>Buy a ticket using the "Buy Ticket" button.</li>
                <li>Wait for the draw time.</li>
                <li>If your ticket is drawn, you win a portion of the total pool 90% of the total pool. Please refer <i>(fees)</i>.</li>
                <li>Claim your winnings through the Winner Winner Chicken Winner to continue to play next draw.</li>
                </ol>
            </Typography>
            
            <Typography variant='body2' fontStyle='italic' padding='10px'>
                <i>(fees):</i> 10% in total winnings amount. Please note that gas fees may apply while purchasing the tickets.
            </Typography>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
};

export default HowToPlayPopup;
