import { Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { shortenAddress } from "../utils/Common";

const LastWinner = (props) => {
    const { lastWin } = props;
    const defaultWin = '';

    return (
        <Marquee gradient='false' speed='60'>
            <Typography variant="body4" color="tex.secondary" align="left">
               Last Winner: <i>{ lastWin !== defaultWin ? shortenAddress(lastWin) : 'No winner today. Be the first winner'}</i>
            </Typography>
        </Marquee>
    );
}

export default LastWinner;