const ethers = require("ethers")

export const shortenAddress = (address) => {
    if (!address) return;
    return `${address.slice(0,7).toLowerCase()}...${address.slice(-5)}`;
}

export const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100).toString().padStart(2, '0');
};

export const generateRandomCode = () => {
    // Encodes the current time as milliseconds since Unix epoch
    const _refix = '97x' + Math.floor(Math.random() * 1997);

    // Encodes the current time as milliseconds since Unix epoch
    const currentTimeEncoded = Date.now().toString().slice(0, 2);

    // Generates a random 5-digit number, ensuring it's exactly 5 digits
    const randomCode5Number = Math.floor(Math.random() * 90000 + 10000).toString();

    // Generates a random 5-character alphanumeric string
    let randomCode5Char = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
        randomCode5Char += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    // Combines all parts into the final code
    return _refix + randomCode5Number + currentTimeEncoded + randomCode5Char;
};

export const formatTime = (time) => {
    const hours = Math.floor(time / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return { hours, minutes, seconds };
};

export const formatTotalPools = (value) => {
    if (value === 0) return value;
    return ethers.utils.formatEther(value - (value * 0.1))
}

export const formatEther = (value) => {
    return `${ethers.utils.formatEther(value)} ETH`

}