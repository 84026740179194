import React from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReplayIcon from '@mui/icons-material/Replay';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { toast, ToastContainer } from 'react-toastify';
import { formatEther } from '../utils/Common';

const AdminControl = ({ lotteryContract, totalCommission }) => {

    const onDrawWinner = async () => {
        const notifications = toast.loading('Drawing Winner Ticket ...')
        try {
            const transaction = await lotteryContract.DrawWinnerTicket();
            await transaction.wait();

            toast.success('A Winner Has Been Selected', {
                id: notifications
            })
            console.log('Draw Winner Ticket successfully');
        } catch (error) {
            console.log('error');
        }
    };

    const onWithDrawCommission = async () => {
        const notifications = toast.loading('Withdrawing commision ...')
        try {
            const transaction = await lotteryContract.WithdrawCommission();
            await transaction.wait();

            toast.success('Withdraw commision successfully', {
                id: notifications
            })
            console.log('Withdraw commision successfully');
        } catch (error) {
            console.log('error');
        }
    };

    const onRestartDraw = async () => {
        const notifications = toast.loading('Restarting draw  ...')
        try {
            const transaction = await lotteryContract.restartDraw();
            await transaction.wait();
            
            toast.success('Restart draw successfully', {
                id: notifications
            })
            console.log('Restart draw successfully');
        } catch (error) {
            console.log('error');
        }
    };

    const onRefundAll = async () => {
        const notifications = toast.loading('Refunding all ...')
        try {
            const transaction = await lotteryContract.RefundAll();
            await transaction.wait();

            toast.success('Refund all successfully', {
                id: notifications
            })
            console.log('Refund all successfully');
        } catch (error) {
            console.log('error');
        }
    };


    return (
        <>
            <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#0b1918', color: 'white' }}>
                <Typography variant="h6" align="center">
                    Admin Controls
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                    Total Commission to be withdrawn: {formatEther(totalCommission)}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                        '& > *': {
                            margin: 1,
                        },
                    }}
                >
                    <Button onClick={onDrawWinner} variant="contained" startIcon={<StarIcon />}>
                        Draw Winner
                    </Button>
                    <Button onClick={onWithDrawCommission} variant="contained" startIcon={<AttachMoneyIcon />}>
                        Withdraw Commission
                    </Button>
                    <Button onClick={onRestartDraw} variant="contained" startIcon={<ReplayIcon />}>
                        Restart Draw
                    </Button>
                    <Button onClick={onRefundAll} variant="contained" startIcon={<MoneyOffIcon />}>
                        Refund All
                    </Button>
                </Box>
            </Paper>
            <ToastContainer />
        </>
    );
};

export default AdminControl;
