import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box mt={4} bgcolor="#0b1918" color="primary.contrastText">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h4" color="inherit">
              Random Ethereum
            </Typography>
            <Typography variant="body2">
              Transparency
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
              Only accepted network Optimism <img src="/op-logo.png" alt="Optimism" style={{ maxWidth: '35%', height: '35px' }} />
            </Typography>
          </Grid>
        </Grid>
        {/* <Box mt={4} display="flex" justifyContent="center">
          <IconButton color="inherit" href="https://facebook.com">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit" href="https://twitter.com">
            <TwitterIcon />
          </IconButton>
          <IconButton color="inherit" href="https://instagram.com">
            <InstagramIcon />
          </IconButton>
          <IconButton color="inherit" href="https://youtube.com">
            <YouTubeIcon />
          </IconButton>
        </Box> */}
        <Typography textAlign="center" mt={4}>
          © {new Date().getFullYear()} Random Ethereum. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
