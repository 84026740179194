import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Use full screen height
                backgroundColor: '#80965a', // A light background color
            }}
        >
            <CircularProgress size={75} /> {/* Loading spinner */}
            <Typography variant="h4" sx={{ mt: 3 }} color="#7100ff">
                Connecting Ethereum (Optimism network)...
            </Typography>
        </Box>
    );
};

export default LoadingPage;
