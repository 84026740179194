/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LotteryContract from './contracts/Lottery.json';
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './components/MainPage';
import LoginPage from './components/LoginPage';
import { toast, ToastContainer } from 'react-toastify';
import LoadingPage from './components/LoadingPage';
 
const ethers = require("ethers")

const { REACT_APP_LOTTERY_ADDRESS, REACT_APP_EXPECTED_NETWORK_ID } = process.env;

function App() {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [remainingTickets, setRemainingTickets] = useState(0);
    const [lotteryContract, setLotteryContract] = useState(null);
    const [currentWinningReward, setCurrentWinningReward] = useState(ethers.BigNumber.from(0));
    const [operatorTotalCommission, setOperatorTotalCommission] = useState(ethers.BigNumber.from(0));
    const [lotteryOperator, setLotteryOperator] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    // PROD: switch to OP network
    async function switchEthereumChainProd() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        try {
            // Attempt to switch network
            await provider.send("wallet_switchEthereumChain", [{ chainId: ethers.utils.hexValue(REACT_APP_EXPECTED_NETWORK_ID) }]);
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    // The network has not been added to MetaMask, so prompt the user to add it
                    await provider.send("wallet_addEthereumChain", [{
                        chainId: ethers.utils.hexValue(REACT_APP_EXPECTED_NETWORK_ID),
                        rpcUrl: "https://example.com/" // TODO: Replace with actual RPC URL of your network
                    }]);
                } catch (addError) {
                    console.error('Failed to add network:', addError);
                    toast.error("Failed to add network. Please add it manually in MetaMask.");
                }
            } else {
                console.error('Failed to switch network:', switchError);
                toast.error("Failed to switch network. Please change it manually in MetaMask.");
            }
            setIsLoading(false);
            return; // Stop further execution
        }
    }

    // Dev: Switch to sepolia network
    async function switchEthereumChainDev() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let result = true;
        try {
            // Attempt to switch to Sepolia if not already connected
            await provider.send("wallet_switchEthereumChain", [{ chainId: ethers.utils.hexValue(11155111) }]);
        } catch (error) {
            if (error.code === 4902) { // Chain not found in the user's wallet
                try {
                    // Prompt user to add Sepolia testnet
                    await provider.send("wallet_addEthereumChain", [{
                        chainId: ethers.utils.hexValue(11155111),
                        rpcUrl: "https://rpc.sepolia.dev",
                        chainName: "Sepolia Testnet",
                        nativeCurrency: {
                        name: "Ether",
                        symbol: "ETH",
                        decimals: 18
                        },
                        blockExplorerUrls: ["https://sepolia.etherscan.io"]
                    }]);
                } catch (addError) {
                    result = false;
                    console.error('Failed to add Sepolia testnet:', addError);
                }
        } else {
                result = false;

                console.error('Failed to switch to Sepolia testnet:', error);
            }
        }
        return result;
    }

    // Fetching smart contract data
    async function fetch_data() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        // Request account access if needed
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const account = await signer.getAddress();
        setCurrentAccount(account);

        // Smart contract initialize
        const contract = new ethers.Contract(REACT_APP_LOTTERY_ADDRESS, LotteryContract.abi, signer);
        setLotteryContract(contract)

        // Retrive user tickets
        const ticketsArray = await contract.getTickets();
        setTickets(ticketsArray);

        // Retrive remaining tickets
        const remaining = await contract.RemainingTickets();
        setRemainingTickets(remaining.toNumber());
        console.log(remaining)
        // Retrive current winning amount (if winner)
        const currentWinning = await contract.CurrentWinningReward();
        setCurrentWinningReward(currentWinning);

        // Retrive total operator commission (for administration)
        const operatorTotalCommission = await contract.operatorTotalCommission();
        setOperatorTotalCommission(operatorTotalCommission);

        // Retrive lottery operator (for administration)
        const lotteryOperator = await contract.lotteryOperator();
        setLotteryOperator(lotteryOperator);
    }

    // Initialize ethers
    async function initialize() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const network = await provider.getNetwork();

            if (network.chainId && network.chainId !== REACT_APP_EXPECTED_NETWORK_ID) {
                const switched = await switchEthereumChainDev()
                // If user agree to switch & no issue while switching
                if (switched) {
                    // toast.success("Switch to OP network successfully!", {})

                    // Showing Main Page
                    setIsLoading(false);
                    setLoggedIn(true);

                    // Fetching contract data
                    await fetch_data();
                } else {
                    setLoggedIn(false);
                    toast.error("Switch network failed or denied by user.", {})
                    return;
                }
            }

            // Correct network and loggedIn before
            if (currentAccount) {
                setLoggedIn(true);
            }

        } catch (error) {
            toast.error("Please install Metamask and connect to OP Mainnet (Optimism) before continue!", {})
            console.log('error');

            // Showing Login Page
            setLoggedIn(false);
            setIsLoading(false); 
        }
    }
    

    const disconnectWallet = async () => {
        setCurrentAccount(null);
        setTickets(0);
        setRemainingTickets(0);
        setLotteryContract(null);
        setLoggedIn(false);
    }

    const handleConnectWallet = async () => {
        if (window.ethereum) {
            try {
                // Start loading & connecting wallet
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                // Request account access if needed
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();

                const account = await signer.getAddress();
                setCurrentAccount(account);

                // Turn off Loader & Showing Login Page
                setIsLoading(false);

                // Initialize ethers & data
                await initialize();

            } catch (error) {
                toast.error("Connect to Metamask Failed!", {})
                console.log('error');
            }
        } else {
            toast.error("Metamask wallet not found!", {})
            console.log("Ethereum object doesn't exist!");
        }
    }

    useEffect(() => {
        async function connectWallet() {
            handleConnectWallet();
        };
        connectWallet();
    }, []);

    return (
        <>
            { isLoading ? (
                <LoadingPage />
            ) : (
            <>
                <Header data={{
                    currentAccount: currentAccount,
                    tickets: tickets,
                    remainingTickets: remainingTickets,
                    lotteryContract: lotteryContract,
                    logout: disconnectWallet
                }} />
                { loggedIn && currentAccount ? (
                    <>
                        <MainPage data={{
                            currentAccount: currentAccount,
                            tickets: tickets,
                            remainingTickets: remainingTickets,
                            lotteryContract: lotteryContract,
                            currentWinningReward: currentWinningReward,
                            operatorTotalCommission: operatorTotalCommission,
                            lotteryOperator: lotteryOperator,
                        }} />
                    </>
                ) : (
                    <>
                        <LoginPage connectWallet={handleConnectWallet} />
                    </>
                )}
                
                <Footer />
                <ToastContainer autoClose={2000} />
            </>
        )}
        </>
    );
}

export default App;
